body {
  background: linear-gradient(120deg, #ffffff, #ffffff);
  height: 100vh;
  font-family: "Quicksand", sans-serif;
}

.Login_form {
  width: 50%;
  padding: 40px;
}
.flex-container {
  justify-content: center;
  display: flex;
}

.panel-title {
  font-size: 30px;
}
.sideNav {
  width: 4% !important;
  position: fixed;
  background: linear-gradient(-45deg, #b5b5b5, #ffffff, #fbf8ff, #dcdcdc);
  height: 100%;
  text-align: center;
  padding-top: 10px;
  box-shadow: 0px 0px 10px black;
  z-index: 50;
}

.sideNav-icon {
  width: 40%;
}

.dashboard-item {
  color: white;
  font-size: 15px;
  padding-left: 26px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.offcanvas {
  background: linear-gradient(-45deg, #ffffff, #ffffff, #ffffff, #ffffff);
  background-size: 300% 400%;
  animation: gradient 10s ease infinite;
  color: rgb(0, 0, 0) !important;
}
.offcanvas-start {
  width: 270px !important;
}
.link {
  text-decoration: none;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.dashboard-item {
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  color: rgb(255, 255, 255);
}
.dashboard-item:hover {
  background: linear-gradient(276deg, rgba(255, 255, 255, 0.898), #ffffff) 0 0
    no-repeat padding-box !important;
  color: black;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.898);
}

.dashboard-item:hover .menu-icon {
  color: black;
}
.offcanvas-body {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.date {
  width: 100%;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  background: linear-gradient(-45deg, #e6f3f5, #e6f1f7, #e2e7eb, #d6d3ff);
  color: rgb(41, 37, 44);
}
.dropdow-supplier {
  width: 100%;
  border-radius: 10px;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 20px;
  background: linear-gradient(-45deg, #e6f3f5, #e6f1f7, #e2e7eb, #d6d3ff);
  color: rgb(41, 37, 44);
  height: 100%;
}
.search-button {
  text-align: right;
  padding-top: 10px;
}

.Api-head {
  color: white;
  margin-top: 50px;
  margin-bottom: 30px;
}
.back-login {
  background-image: url("../src/Image/side1.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  padding-top: 9%;
}
.main-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.autoUser-data {
  padding: 10px;
}
.autoUser-data table {
  color: white;
  text-align: center;
}
.head {
  font-weight: 800;
  font-size: 17px !important;
  color: black !important;
}
.flightDetailData div {
  font-size: 16px;
  margin-bottom: 4px;
  color: gray;
  text-align: center;
}
.flightDetail table {
  color: black;
}
.autoUser-data th {
  padding: 10px;
  background-color: transparent;
}
.autoUser-data td {
  padding: 10px;
  background-color: transparent;
}
.Search-autoUser {
  width: 30%;
  background-color: #ffffff;
  border-right: 0px;
  border-color: #fd8c00;
  padding: 5px 10px;
  box-shadow: 0px 0px 10px #524d46;
  border-radius: 30px;
  color: rgb(255, 255, 255);
  padding-left: 20px;
  padding-top: 7px;
}
/* form{
    justify-content: center;
    display: flex;
    align-items: center;
} */
.login-form {
  padding: 4% 2%;
  border: 0px solid #212121;
  border-radius: 20px;
  box-shadow: 0px 0px 10px #ff7900;
}
.btn-submit {
  width: 100%;
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  border-radius: 20px;
  border: 1px solid white;
  margin-top: 25px;
  padding: 10px;
  color: white !important;
}
.btn-search {
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  width: 20%;
  border-radius: 20px;
  padding: 0px 10px;
  float: right;
  color: white;
}
.login-heading {
  color: #fd8c00;
  text-align: center;
  font-weight: 900;
  margin-bottom: 40px;
  font-size: 25px;
}
.input-login {
  background: #ffffff;
  border-bottom: 1px solid #fd8c00;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.input-login:focus {
  background: transparent;
  color: rgb(0, 0, 0);
  border-bottom: 1px solid white;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.input-login:active {
  background: transparent;
  border-bottom: 1px solid white;
  color: white;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  border-radius: 0px;
}
.pull-right {
  color: #7c7c7c;
}
.form-control:focus {
  box-shadow: none;
}

.forgot {
  font-size: 14px;
  text-align: center;
  margin-top: 40px;
  color: #fd8b00;
}
.modal-content {
  /* background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #E13535) 0 0 no-repeat padding-box !important; */
  box-shadow: 0px 0px 10px rgb(0 0 0);
  /* color: white; */
}
.modal-body {
  background-color: transparent;
}
.tick {
  width: 100% !important;
}
.filter {
  display: flex;
  justify-content: center;
  padding-top: 6%;
}
.autoUser {
  background-color: #ffffff;
  border: none;
  margin-left: 10px;
  color: rgb(0, 0, 0);
}
.autoUser:focus {
  outline: none;
}
input:focus {
  border: none;
}
.card {
  background: transparent;
  box-shadow: 0px 0px 10px rgb(0 0 0);
  color: white;
  padding: 10px;
}
.img-flight {
  align-items: right;
}
.img-airline {
  width: 30%;
}
.dates {
  font-size: 12px;
  color: #ffffff;
  background: transparent;
  border: 2px solid white;
  padding: 4px;
}
.airport {
  padding: 10px;
  justify-content: center;
  display: flex;
}
.Middle {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 0px;
  margin-bottom: 20px;
}
.Middle2 {
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  margin-bottom: 0px;
}
.plane {
  padding: 0px;
  text-align: center;
}
/* .fa-solid{
    border:1px solid white;
    padding: 2px;
    color: rgb(145 21 223);
} */
.search-icon {
  color: black;
}
.btn-book {
  background-color: #ffffff !important;
  border: 0px solid #fd8c00;
  border-radius: 2px;
  padding: 0px 10px;
  height: 100%;
}
* {
  padding: 0;
  margin: 0;
}

.wrapper {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #fd8c00;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 20%;

  position: relative;
  background: black;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fd8c00;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px #7ac142;
  /* animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both; */
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }

  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

.menu-icon {
  color: #ffffff;
}

.page-icon {
  color: rgba(255, 81, 0, 0.898);
}
.logout {
  position: absolute;
  bottom: 6%;
  width: 90%;
  padding-bottom: 9px;
  border-radius: 100px;
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  left: 6%;
  font-size: 15px;
  box-shadow: 0px 0px 10px #1e1e1ede;
  padding-top: 10px;
  color: white;
  text-align: center;
  justify-content: center;
}

.sideNav-menu {
  color: #fd6500;
}
.offcanvas-header {
  padding: 2rem 2rem 8px 2rem !important;
}
.checkmark__check {
  stroke: #ff8d00;
}

.check {
  padding: 17px;
  width: 50%;
}
.booking {
  font-size: 12px;
  margin-bottom: 2px;
  color: #b068de;
}
.user {
  display: flex;
  justify-content: right;
  position: fixed;
  min-height: 60px;
  width: 100%;

  background: linear-gradient(276deg, rgb(245 242 248), #f3f1f6) 0 0 no-repeat
    padding-box !important;
}
.user-button {
  position: fixed;
  width: 2%;
  top: 5px;
  right: 1%;
}
/* .btn-primary{
    color: transparent;
    background-color: transparent;
    border-color: transparent;
} */
/* .btn-primary:hover{
    color: transparent;
    background-color: transparent;
    border-color: transparent;
} */
/* .btn-primary:focus{
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    box-shadow:none
} */
.dropdown .btn-primary {
  color: inherit;
  background-color: inherit;
  border-color: inherit;
}
.dropdown_Scroll {
  overflow: scroll;
  max-height: 400px;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: -0.95em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.user .dropdown-toggle:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: middle;
  content: "";
  border-top: 7px solid #f00;
  border-right: 7px solid transparent;
  border-bottom: 0;
  border-left: 7px solid transparent;
}
.user .dropdown-menu {
  padding: 0;
}
.user .user-button {
  position: fixed;
  width: 2%;
  top: 5px;
  right: 45px;
}
.user .dropdown-toggle {
  border: none;
}
.user .dropdown-menu .btn-logout {
  border: none;
}
.user .dropdown-menu .btn-logout .dropdown-item {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  font-weight: 400;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  border: none;
  outline: none;
}
.user .dropdown-menu a {
  border: none;
  padding: 0;
}
.btn-primary:active {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.show > .btn-primary.dropdown-toggle {
  color: transparent;
  background-color: transparent;
  border-color: transparent;
  box-shadow: none;
}
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none;
}
.loader {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
}

.ModalFull {
  background: rgba(0, 0, 0, 0.8);
}
.tick-icon {
  justify-content: center;
  display: flex;
}
.pnr-status {
  font-size: 17px;
  color: white;
  padding: 18px;
}
.booking-pnr {
  font-size: 15px;
}
.modal-dialog {
  max-width: 680px;
}
.pnr-status-icon {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 15px;
}
.dropdown-sidenav {
  color: white;
  font-size: 15px;
  border: 0px;
  box-shadow: 0px 0px 10px black;
  width: 85%;
  margin-left: 9%;
  border-radius: 100px;
  margin-top: 13px;
}
.show-book {
  position: relative !important;
  background: red !important;
}
.list-Booking {
  margin-top: 100px;
}
.booktable {
  color: white;
  width: 100%;
  border: 0px solid white;
  box-shadow: 0px 0px 11px black;
  text-align: center;
}
.booktable th {
  box-shadow: 0px 0px 2px white;
  padding: 10px;
  font-size: 18px;
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
}
.booktable td {
  box-shadow: 0px 0px 2px rgb(187, 187, 187);
  padding: 10px;
  font-size: 14px;
  color: black;
}
.btn-Book {
  background-image: linear-gradient(45deg, #e29300, #ff8605);
  color: white;
  padding: 10px;
  margin-top: 10px;
}

.loadergif {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
}

.flightDetail {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  box-shadow: 0px 0px 10px rgb(0, 0, 0);
  color: rgb(255, 255, 255);
  padding: 10px;
  margin-top: 20px;
}
.flightDetailTitle {
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  box-shadow: 0px 0px 10px rgb(0 0 0);
  color: white;
  padding: 10px;
}
.flightDetailData {
  color: rgb(0, 0, 0);
  padding-top: 20px;
}
.flightDetailData1 {
  color: rgb(0, 0, 0);
  padding-top: 0px;
  padding-left: 30px;
}
.black-line {
  color: black;
}

.Pre-Search {
  color: white;
  text-align: center;
  display: flex;
  justify-content: center;
}
.Pre-Search table {
  box-shadow: 0px 0px 10px #fd8c00;
}
.Pre-Search th {
  padding: 20px 40px 20px 40px;
  background-color: #fd8c00 !important;
  color: black;
}
.Pre-Search td {
  /* box-shadow: 0px 0px 10px #fd8c00; */
  color: black;
  padding: 10px 40px 10px 40px;
}
.Okay {
  float: right;
  margin-top: 15px;
  background-color: #fd8c00 !important;
  border: 1px solid #fd8c00;
  color: black;
}

.payment-detail {
  margin-top: 40px;
  margin-bottom: 20px;
}

.btn-auto {
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}

.btn-add {
  background: linear-gradient(276deg, rgba(0, 110, 255, 0.898), #01568f) 0 0
    no-repeat padding-box !important;

  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 4px;
}
.btn-modify {
  background: linear-gradient(276deg, rgba(1, 130, 37, 0.898), #006a35) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 93%;
  border-radius: 4px;
}
.btn-delete {
  background: linear-gradient(276deg, rgba(255, 0, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  color: white;
  border: 1px solid white;
  padding-left: 20px;
  padding-right: 20px;
  display: inline-block;
  padding-top: 5px;
  width: 93%;
  padding-bottom: 5px;
  border-radius: 4px;
}

.icon-add {
  color: white !important;
}

.UserData table {
  width: 100%;
  box-shadow: 0px 0px 10px black;
}
.UserData th {
  padding: 10px;
  font-size: 20px;
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  color: white;
  text-align: center;
}
.UserData td {
  padding-left: 10px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
  font-size: 15px;
  border: 1px solid rgb(228, 228, 228);
}
.AddUser {
  float: right;
}
.AddUserContainer {
  padding-top: 2%;
  padding-right: 2%;
  margin-top: 3%;
}

.addUserData {
  width: 100%;
  margin-top: 10px;
  padding: 8px;
}

.transaction {
  padding: 5px !important;
  margin-top: 8px !important;
}
.amount {
  padding: 5px !important;
  margin-top: 8px !important;
}
.BookingLayout {
  padding-left: 5%;
  padding-right: 1%;
  padding-top: 1%;
}

.PaymentLayout {
  padding-left: 5%;
  padding-right: 1%;
  padding-top: 1%;
  margin-top: 5%;
}
.TripType {
  float: right;
  padding-left: 15px;
  padding-right: 15px;
  border: none;
  color: white;
  background: linear-gradient(276deg, rgba(255, 81, 0, 0.898), #e13535) 0 0
    no-repeat padding-box !important;
  border-radius: 3px;
  box-shadow: 0px 0px 5px white;
  font-weight: 800;
}
.fareDetailINFO {
}

.fareTitleINFO {
  background-color: rgb(221, 221, 221);
}
.fareTitleINFO2 {
  background-color: rgb(221, 221, 221);
  padding: 20px;
}

.updatedForm {
  display: flex;
}
.titleFare {
  padding: 10px;
  text-align: center;
}

.accordion-button {
  padding: 6px !important;
}

.fare-summary-Box {
  padding-bottom: 60px;
}
.btn-logout {
  background-color: #96d5ff;
  width: 100%;
}
.logout-link {
  text-decoration: none;
}
.container.form-content {
  margin-top: 5%;
}
.form-group input {
  border: 1px solid #d3d3d3;
  padding: 6px 7px;
  border-radius: 5px;
  width: 100%;
}
.form-group select {
  border: 1px solid #d3d3d3;
  padding: 6px 7px;
  border-radius: 5px;
  width: 100%;
}
.form-group label {
  width: 100%;
  margin-bottom: 5px;
  font-weight: 600;
  font-size: 15px;
}
.form-group {
  margin-bottom: 8px;
}
.form-group input[type="submit"] {
  background-color: #e13535;
  border: none;
  border-radius: 5px;
  padding: 5px 0;
  font-size: 16px;
  text-transform: uppercase;
  max-width: 200px;
  font-weight: 500;
}
.form-head {
  font-size: 32px;
  text-align: center;
  margin-bottom: 22px;
  /* text-transform: uppercase; */
  font-weight: 600;
}
.form-box {
  background: #e9ecef;
  max-width: 70%;
  margin: 100px auto;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 34%);
}
.website_queries {
  background: #e9ecef;
  max-width: 100%;
  margin: 102px 149px 120px 226px;
  padding: 40px 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 34%);
}
.form-box .container.form-content {
  margin: 0;
}
.form-group input[type="file"] {
  background: #fff;
  border: 1px solid #d3d3d3;
  padding: 3px 7px;
}

.accordion .accordion-item {
  padding: 0px !important;
  background-color: transparent !important;
  border: 0px !important;
}
.accordion .accordion-button {
  background: transparent;
  border: none;
  box-shadow: none !important;
}
.accordion-item .accordion-button {
  padding: 2px !important;
  margin: 0;
  color: #fff;
  font-size: 15px;
  width: 100%;
}
.accordion-item .accordion-button:after {
  position: absolute;
  right: 10px;
  color: #fff;
  filter: brightness(0) invert(1);
}
.dashboard-item:hover .accordion-button {
  color: #333;
}
.dashboard-item:hover .accordion-button:after {
  filter: brightness(0%);
}
.accordion-collapse .accordion-body {
  padding: 0;
  margin-top: 25px;
}
.form-head-product {
  color: orangered;
}

.search-leads button.btn {
  position: absolute;
  top: 0;
  right: 0px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-leads {
  position: relative;
}
.leadstatustable thead tr th {
  padding: 5px;
}
.leadstatustable tbody tr td {
  padding: 5px;
  color: #000;
  font-weight: 500;
}
.leadstatustable {
  width: 100%;
}
h4.statushead {
  text-align: center;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
}

.form-container .form-box {
  max-width: 88%;
}
.form-box.spaces {
  margin-bottom: 50px;
  margin-top: 0;
}
.form-container {
  margin-top: 70px;
}
.lead-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000;
}
.lead-list-tabel {
  padding: 0;
}

.leads .jfrgbA {
  padding-left: 6px;
  padding-right: 6px;
}

.check-list input[type="checkbox"] {
  margin-right: 6px;
}
.check-list label {
  font-size: 15px;
  color: #333;
  font-weight: 500;
  cursor: pointer;
}
.check-list {
  display: inline-block;
  margin-right: 12px;
  cursor: pointer;
  margin-top: 10px;
}
label.custom-label {
  font-weight: 500;
  font-size: 15px;
  display: block;
}
.loader-lotti {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  /* background: #000000bf; */
}
.parent-form {
  display: none;
}
.form-group.parent {
  border-right: 1px solid #3333331c;
}
.lft {
  width: 48%;
  float: left;
}
.rht {
  width: 48%;
  float: right;
}
.custom {
  margin-bottom: 0px;
  font-weight: 600;
  font-size: 15px;
  display: block;
}

.radio {
  padding-right: 5px;
}
.radio label {
  padding-left: 5px;
}
.filter-box-1 {
  margin-bottom: 20px;
}
.submt {
  margin-top: 30px;
}
.clear-btn {
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #e13535;
  border-color: #e13535;
  float: right;
  margin-left: 10px;
}

.clear-btn-success {
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #198754;
  border-color: #198754;
  float: right;
}

.clear-btn-success:hover {
  background: #157347;
  color: #fff;
  border-color: #146c43;
}

.clear-btn:hover {
  background: #b02a37;
  color: #fff;
  border-color: #b02a37;
}
.filter-box-3 {
  margin-bottom: 20px;
}
.filter-box-2 {
  margin-bottom: 20px;
}
.input-error {
  background: #ff000017;
  border-color: red !important;
}
.input-success {
  background: #32ea2226;
  border-color: #32ea22 !important;
}
.input-error-message {
  font-style: italic;
  color: red;
  font-size: 14px;
  margin-top: 2px;
  display: block;
}

.input-success-message {
  font-style: italic;
  color: #32ea22;
  font-size: 14px;
  margin-top: 2px;
  display: block;
}

.form-box.devider .left {
  position: relative;
}
.form-box.devider .left:before {
  content: "";
  background: #3333333d;
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
}
.form-box.devider h2 {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.form-box.devider .right span {
  display: block;
  font-weight: 600;
  color: #fd6500;
  font-size: 15px;
}
h2.form-subHead {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 10px;
  padding-bottom: 10px;
}
.error-message {
  font-style: italic;
  color: red;
  font-size: 14px;
  margin-top: 2px;
}

/* =====model======= */
.modelone .modal-dialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: 0;
}
.modelone .modal-dialog .modal-content {
  box-shadow: none;
  border-radius: 10px;
}
.modelone .modal-dialog .modal-header {
  text-align: center;
  justify-content: center;
  padding-top: 10px;
  padding-bottom: 10px;
}
.modelone .modal-dialog .modal-header .modal-title {
  text-align: center;
  display: block;
  width: 100%;
  font-size: 23px;
  font-weight: 600;
}
.modelone .modal-dialog button.btn-close {
  display: none;
}
.modelone .modal-dialog .modal-body {
  padding-bottom: 0;
  text-align: center;
}
.modelone .modal-dialog .modal-body label.form-label {
  margin-bottom: 20px;
}
.modelone .modal-dialog .modal-footer button.btn {
  padding: 5px 15px;
  font-size: 16px;
}
.modelone .modal-dialog .modal-footer button.btn {
  padding: 5px 15px;
  font-size: 16px;
}
.modelone .modal-dialog button.btn.btn-primary {
  background: #17a2b8;
  border-color: #17a2b8;
}
.modelone .modal-dialog button.btn.btn-secondary {
  background: #333;
  border-color: #333;
}
.modal.show .modal-dialog textarea {
  min-height: 150px;
}
.modelone .modal-dialog .modal-footer {
  border-top: none;
  padding-top: 0;
  justify-content: center;
}
.lead-review .led-user {
  margin: 10px 0;
  color: #000;
  font-size: 16px;
  padding-left: 12px;
  font-weight: 500;
}
.lead-review button.btn {
  background: #17a2b8;
  border-color: #17a2b8;
  color: #fff;
  padding: 5px 15px;
  font-weight: 500;
}
.lead-review button.btn:focus {
  box-shadow: none;
}

.form-box.welcomebx-index {
  border: solid 1px #ffffff;
  background: url("../src/Image/slider-bg-top.jpg") bottom center #11367a;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  -webkit-box-shadow: 0px 10px 25px rgb(0 0 0 / 34%);
  -moz-box-shadow: 0px 10px 25px rgb(0 0 0 / 34%);
  box-shadow: 0px 10px 25px rgb(0 0 0 / 34%);
}
.user-logoutmenu-img {
  border-radius: 50%;
  width: 42px;
  max-width: none;
}
/* .user-logoutmenu-login {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: rgb(86, 202, 0);
  box-shadow: rgb(255 255 255) 0px 0px 0px 2px;
  display: inline-block;
  position: absolute;
  right: 1px;
  bottom: 4px;
} */
h1.heading1 {
  color: #fff;
  margin: 0;
  text-transform: uppercase;
  font-weight: bold;
  border-bottom: 1px dashed #ffffff4a;
  padding-bottom: 20px;
}
.rsetting-head {
  margin-left: 40px;
}
.rsetting-head.bell [role="tab"] {
  font-size: 13px;
  font-weight: 700;
  height: 60px;
  text-align: center;
  width: 50%;
  justify-content: center;
  font-family: "Quicksand", sans-serif;
}
.accordion-collapse.collapse.show .accordion-body {
  margin-top: 12px;
}
.accordion-collapse.collapse.show .dashboard-item {
  background: var(--bs-gray-200) !important;
  border-radius: 8px;
  color: #333;
  opacity: 1;
  width: 80%;
  margin: 0;
  font-weight: 600;
  margin-left: auto;
  margin-bottom: 12px;
  margin-right: auto;
  padding-left: 35px;
  box-shadow: none;
}
.accordion-collapse.collapse.show .dashboard-item i {
  color: #333;
}
.accordion-collapse.collapse .accordion-body .dashboard-item {
  background: var(--bs-gray-200) !important;
}
